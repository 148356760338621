export var WALLET_ID;
(function (WALLET_ID) {
    WALLET_ID["METAMASK"] = "metaMask";
})(WALLET_ID || (WALLET_ID = {}));
export var INFO_TOKEN_TYPE;
(function (INFO_TOKEN_TYPE) {
    INFO_TOKEN_TYPE[INFO_TOKEN_TYPE["LOCKED"] = 0] = "LOCKED";
    INFO_TOKEN_TYPE[INFO_TOKEN_TYPE["UNLOCKED"] = 1] = "UNLOCKED";
    INFO_TOKEN_TYPE[INFO_TOKEN_TYPE["RELEASED"] = 2] = "RELEASED";
    INFO_TOKEN_TYPE[INFO_TOKEN_TYPE["RELEASABLE"] = 3] = "RELEASABLE";
})(INFO_TOKEN_TYPE || (INFO_TOKEN_TYPE = {}));
