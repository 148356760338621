import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useCallback, useContext, useState, } from "react";
export const MenuContext = createContext({
    isOpenMenu: false,
    showMenu: () => {
        //
    },
    closeMenu: () => {
        //
    },
    toggleMenu: () => {
        //
    },
});
// Create a component that will wrap your routes
export const MenuProvider = ({ children }) => {
    const [isOpen, setIsOpen] = useState(true);
    const handleShowMenu = useCallback(() => {
        setIsOpen(true);
    }, []);
    const handleCloseMenu = useCallback(() => {
        setIsOpen(false);
    }, []);
    const toggleMenu = useCallback(() => {
        setIsOpen(!isOpen);
    }, [isOpen]);
    return (_jsx(MenuContext.Provider, { value: {
            isOpenMenu: isOpen,
            showMenu: handleShowMenu,
            closeMenu: handleCloseMenu,
            toggleMenu: toggleMenu,
        }, children: children }));
};
export const useMenuContext = () => {
    return useContext(MenuContext);
};
