var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Button, Grid } from "@mui/material";
import { waitForTransaction } from "@wagmi/core";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { IconWallet } from "src/assets";
import { CHAIN_ENV, CHAIN_SUPPORT_ID, HYPE_SEPOLIA_ADDRESS } from "src/config";
import { ALERT_TYPE } from "src/constant";
import { useAlertContext } from "src/contexts";
import { INFO_TOKEN_TYPE } from "src/types";
import { CopyText, InfoTokenBox, ModalClaim, TimelineVesting, } from "src/ui/components";
import { formatSINumber, shortenHex, timestampToDate } from "src/ui/utils";
import { formatUnits } from "viem";
import { useAccount } from "wagmi";
import { ModalConfirmClaim } from "./component";
import { useDashboard } from "./hook/useDashboard";
export const Dashboard = () => {
    var _a;
    const { t } = useTranslation();
    const { showAlert, handleOpenSelectWallet } = useAlertContext();
    const { isConnected } = useAccount();
    const { currentRound, totalTokenAmount, listVestingRound, claimedAmount, releasableTokenAmount, timeStart1stRound, timeStartLock, timeStart1stRoundDisplay, timeNextRound, timeNextRoundDisplay, timeCurrentRoundDisplay, timeLastUserClaimedDisplay, unlockAmountDisplay, totalTokenAmountDisplay, tokenAmountNextRoundDisplay, releasableTokenAmountDisplay, claimedAmountDisplay, isLoadingValue, handleClaimToken, } = useDashboard();
    const [isOpen, setIsOpen] = useState(false);
    const [isOpenConfirm, setIsOpenConfirm] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [gridHeight, setGridHeight] = useState(0);
    const gridRef = useRef(null);
    const height = ((_a = gridRef.current) === null || _a === void 0 ? void 0 : _a.getBoundingClientRect().height) || 0;
    const handleOpenModalClaim = () => __awaiter(void 0, void 0, void 0, function* () {
        if (!isConnected) {
            handleOpenSelectWallet();
            return;
        }
        setIsOpen(true);
    });
    const handleAcceptClaim = () => __awaiter(void 0, void 0, void 0, function* () {
        setIsLoading(true);
        const hash = yield handleClaimToken();
        if (hash) {
            const { status } = yield waitForTransaction({
                hash: hash,
            });
            if (status === "success") {
                showAlert({
                    message: (_jsxs(Box, { sx: { display: "flex", alignItems: "center" }, children: [t("transactionHash"), ":\u00A0", _jsx(CopyText, { text: shortenHex(hash), valueCopy: hash })] })),
                    title: t("claimSuccessfullyTitle"),
                    titleAlert: t("claimSuccessfully"),
                    type: ALERT_TYPE.SUCCESS,
                });
                setIsOpenConfirm(false);
                setIsOpen(false);
                setIsLoading(false);
            }
        }
        else {
            setIsOpenConfirm(false);
            setIsOpen(false);
            setIsLoading(false);
        }
    });
    useEffect(() => {
        setGridHeight(height + 16);
    }, [height]);
    console.log(CHAIN_SUPPORT_ID, CHAIN_ENV);
    return (_jsxs(Box, { sx: {
            padding: "10px 50px 0 50px",
            display: "flex",
            flexDirection: "column",
            overflow: "auto",
            marginBottom: "10px",
        }, children: [_jsxs(Box, { sx: {
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginBottom: "20px",
                }, children: [_jsxs(Box, { sx: {
                            display: "flex",
                            alignItems: "center",
                            gap: 1,
                            borderRadius: "40px",
                            background: "white",
                            padding: "10px 15px",
                        }, children: [_jsx(IconWallet, { width: 18, height: 18 }), "HYPE ", t("tokenAddress"), ":\u00A0", _jsx(CopyText, { text: shortenHex(HYPE_SEPOLIA_ADDRESS), valueCopy: HYPE_SEPOLIA_ADDRESS })] }), _jsx(Button, { variant: "contained", color: "info", onClick: handleOpenModalClaim, children: t("claimVestedHYPE") })] }), _jsxs(Grid, { container: true, spacing: 2, sx: { height: "100%", overflow: "hidden" }, children: [_jsx(Grid, { item: true, xs: 6, sx: { height: "100%", overflow: "hidden" }, children: _jsxs(Grid, { ref: gridRef, container: true, rowSpacing: 0.25, columnSpacing: 1, sx: { height: "100%", overflow: "hidden" }, children: [_jsx(Grid, { item: true, xs: 6, children: _jsx(InfoTokenBox, { type: INFO_TOKEN_TYPE.LOCKED, amount: totalTokenAmountDisplay, date: timeStart1stRoundDisplay, isLoading: isLoadingValue }) }), _jsx(Grid, { item: true, xs: 6, children: _jsx(InfoTokenBox, { type: INFO_TOKEN_TYPE.UNLOCKED, amount: unlockAmountDisplay, date: timeCurrentRoundDisplay, isLoading: isLoadingValue }) }), _jsx(Grid, { item: true, xs: 6, children: _jsx(InfoTokenBox, { type: INFO_TOKEN_TYPE.RELEASED, amount: claimedAmountDisplay, date: timeLastUserClaimedDisplay, isLoading: isLoadingValue }) }), _jsx(Grid, { item: true, xs: 6, children: _jsx(InfoTokenBox, { type: INFO_TOKEN_TYPE.RELEASABLE, amount: releasableTokenAmountDisplay, date: timeNextRoundDisplay, isLoading: isLoadingValue }) })] }) }), _jsx(Grid, { item: true, xs: 6, sx: {
                            height: `${gridHeight}px`,
                            overflow: "hidden",
                        }, children: _jsx(TimelineVesting, { data: listVestingRound, startTime: timeStartLock, time1stRound: timeStart1stRound, amount: totalTokenAmount, currentRound: currentRound }) })] }), isOpen && (_jsx(ModalClaim, { open: isOpen, onCancel: () => setIsOpen(false), claimedToken: formatSINumber(formatUnits(BigInt(claimedAmount), 18)), releasableTokenAmount: releasableTokenAmountDisplay, vestedTokenAmount: unlockAmountDisplay, onClickClaim: () => setIsOpenConfirm(true), timeNextRound: timestampToDate(timeNextRound), tokenAmountNextRound: tokenAmountNextRoundDisplay, isAvailableClaim: releasableTokenAmount.isGreaterThan(0) })), isOpenConfirm && (_jsx(ModalConfirmClaim, { isLoading: isLoading, tokenAmount: formatSINumber(formatUnits(BigInt(Number(releasableTokenAmount || 0)), 18)), open: isOpenConfirm, onConfirm: handleAcceptClaim, onCancel: () => setIsOpenConfirm(false) }))] }));
};
