var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
const Icon = (_a) => {
    var { width, height } = _a, props = __rest(_a, ["width", "height"]);
    return (_jsxs("svg", Object.assign({ width: width || "24", height: height || "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, props, { children: [_jsx("rect", { width: "24", height: "24", rx: "12", fill: "#E31D1C" }), _jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M10.793 7.22156L10.0669 7.65352L10.2325 6.81058L9.62793 6.17973L10.4466 6.14561L10.793 5.35852L11.1394 6.14561H11.9567L11.3534 6.81058L11.5351 7.65352L10.793 7.22156Z", fill: "#FECA00" }), _jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M5.04429 10.805L2.97368 11.9036L3.44598 9.75986L1.72168 8.15546L4.05648 8.06871L5.04429 6.06696L6.0321 8.06871H8.36294L6.64259 9.75986L7.16052 11.9036L5.04429 10.805Z", fill: "#FECA00" }), _jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M12.2305 10.0551L11.5044 10.487L11.67 9.64408L11.0654 9.01322L11.8841 8.97911L12.2305 8.19202L12.5769 8.97911H13.3942L12.7909 9.64408L12.9726 10.487L12.2305 10.0551Z", fill: "#FECA00" }), _jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M11.5117 12.8883L10.7857 13.3203L10.9513 12.4774L10.3467 11.8464L11.1653 11.8124L11.5117 11.0253L11.8581 11.8124H12.6754L12.0722 12.4774L12.2538 13.3203L11.5117 12.8883Z", fill: "#FECA00" }), _jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M9.35545 15.0134L8.62944 15.4453L8.79504 14.6024L8.19043 13.9715L9.00909 13.9374L9.35545 13.1503L9.70189 13.9374H10.5192L9.91593 14.6024L10.0976 15.4453L9.35545 15.0134Z", fill: "#FECA00" })] })));
};
export default Icon;
