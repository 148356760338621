var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
const Icon = (_a) => {
    var { width, height } = _a, props = __rest(_a, ["width", "height"]);
    return (_jsxs("svg", Object.assign({ width: width || "80", height: height || "80", viewBox: "0 0 80 80", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, props, { children: [_jsx("path", { d: "M40.0003 6.66675C21.6337 6.66675 6.66699 21.6334 6.66699 40.0001C6.66699 58.3667 21.6337 73.3334 40.0003 73.3334C58.367 73.3334 73.3337 58.3667 73.3337 40.0001C73.3337 21.6334 58.367 6.66675 40.0003 6.66675ZM55.9337 32.3334L37.0337 51.2334C36.567 51.7001 35.9337 51.9667 35.267 51.9667C34.6003 51.9667 33.967 51.7001 33.5003 51.2334L24.067 41.8001C23.1003 40.8334 23.1003 39.2334 24.067 38.2667C25.0337 37.3001 26.6337 37.3001 27.6003 38.2667L35.267 45.9334L52.4003 28.8001C53.367 27.8334 54.967 27.8334 55.9337 28.8001C56.9003 29.7667 56.9003 31.3334 55.9337 32.3334Z", fill: "url(#paint0_linear_432_4232)" }), _jsx("defs", { children: _jsxs("linearGradient", { id: "paint0_linear_432_4232", x1: "23.9145", y1: "6.13167", x2: "84.9192", y2: "56.6438", gradientUnits: "userSpaceOnUse", children: [_jsx("stop", { stopColor: "#77DBC1" }), _jsx("stop", { offset: "1", stopColor: "#53AF97" })] }) })] })));
};
export default Icon;
