var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box } from "@mui/material";
import { disconnect, getNetwork, signMessage, watchNetwork } from "@wagmi/core";
import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { LAYOUT_CONFIG } from "src/config";
import { METAMASK_ERROR_CODE, TOKEN_KEY } from "src/constant";
import { useAlertContext, useMenuContext, useMessageContext, } from "src/contexts";
import { AuthService, InvestorService } from "src/services";
import { sideBarWidthClose, sideBarWidthOpen } from "src/ui/constants";
import { useAccount } from "wagmi";
import { Footer } from "../Footer";
import { Navbar } from "../Navbar";
import { Sidebar } from "../Sidebar";
import styles from "./Dashboard.module.scss";
export const DashboardLayout = ({ children }) => {
    const { isOpenMenu } = useMenuContext();
    const { t } = useTranslation();
    const { handleRefreshData, handleOpenAlertWrongNetwork, handleCloseAlertWrongNetwork, } = useAlertContext();
    const { connector: activeConnector } = useAccount();
    const { showMessage } = useMessageContext();
    const { isUseSidebar, isUseFooter, isUseNavbar } = LAYOUT_CONFIG;
    const sideBarWidth = useMemo(() => (isOpenMenu ? sideBarWidthOpen : sideBarWidthClose), [isOpenMenu]);
    useEffect(() => {
        const handleConnectorUpdate = ({ account }) => __awaiter(void 0, void 0, void 0, function* () {
            var _a;
            try {
                if (account) {
                    if ((_a = getNetwork().chain) === null || _a === void 0 ? void 0 : _a.unsupported)
                        return;
                    handleCloseAlertWrongNetwork();
                    localStorage.removeItem(TOKEN_KEY.TOKEN);
                    const nonceData = yield AuthService.getNonce({ address: account });
                    const nonce = nonceData.data.data.nonce;
                    if (nonce) {
                        const signature = yield signMessage({
                            message: nonce,
                        });
                        if (signature) {
                            const res = yield AuthService.login({
                                address: account,
                                signature,
                            });
                            if (res.data.data.token) {
                                localStorage.setItem(TOKEN_KEY.TOKEN, res.data.data.token);
                                const result = yield InvestorService.getDashboard();
                                if (result.data.data) {
                                    showMessage(t("loginSuccess"));
                                    handleRefreshData();
                                }
                            }
                        }
                    }
                }
            }
            catch (err) {
                console.error(err);
                if (err.code === METAMASK_ERROR_CODE.REJECT) {
                    yield disconnect();
                    localStorage.removeItem(TOKEN_KEY.TOKEN);
                    return;
                }
            }
        });
        if (activeConnector) {
            activeConnector.on("change", handleConnectorUpdate);
        }
        return () => activeConnector === null || activeConnector === void 0 ? void 0 : activeConnector.off("change", handleConnectorUpdate);
    }, [
        activeConnector,
        handleCloseAlertWrongNetwork,
        handleRefreshData,
        showMessage,
        t,
    ]);
    useEffect(() => {
        const unWatchNetwork = watchNetwork(({ chain }) => {
            if (chain === null || chain === void 0 ? void 0 : chain.unsupported) {
                //TODO: switch chain to support chain
                handleOpenAlertWrongNetwork();
            }
        });
        return () => unWatchNetwork();
    }, [handleOpenAlertWrongNetwork]);
    // fix bug scroll top (to improve update react router dom to V6 and use ScrollRestoration)
    useEffect(() => {
        setTimeout(() => window.scrollTo(0, 0));
    }, []);
    return (_jsxs(Box, { className: styles.container, children: [isUseSidebar && _jsx(Sidebar, {}), _jsxs(Box, { className: styles.contentContainer, sx: {
                    width: `calc(100vw - ${isUseSidebar ? sideBarWidth : 0}px)`,
                }, children: [isUseNavbar && _jsx(Navbar, {}), children] }), isUseFooter && _jsx(Footer, {})] }));
};
