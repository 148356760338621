import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Button, Dialog, DialogContent, DialogTitle, IconButton, } from "@mui/material";
import { useTranslation } from "react-i18next";
import { IconClose, IconMetamask } from "src/assets";
import { WALLET_ID } from "src/types";
export const ModalSelectWallet = ({ open, onCancel, onSelectWallet, }) => {
    const { t } = useTranslation();
    return (_jsxs(Dialog, { open: open, fullWidth: true, children: [_jsxs(DialogTitle, { sx: {
                    wordWrap: "break-word",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                }, children: [t("importYourWallet"), _jsx(IconButton, { onClick: onCancel, children: _jsx(IconClose, {}) })] }), _jsx(DialogContent, { children: _jsxs(Button, { sx: {
                        width: "100%",
                        height: "150px",
                        border: "1px solid",
                        borderRadius: "12px",
                        borderColor: (theme) => theme.palette.grey[500],
                        color: (theme) => theme.palette.grey[500],
                        display: "flex",
                        flexDirection: "column",
                        gap: 1,
                    }, onClick: () => {
                        onSelectWallet && onSelectWallet(WALLET_ID.METAMASK);
                        onCancel && onCancel();
                    }, children: [_jsx(IconMetamask, { width: 80, height: 80 }), _jsx(Box, { children: "Metamask" })] }) })] }));
};
