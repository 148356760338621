var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
const Icon = (_a) => {
    var { width, height } = _a, props = __rest(_a, ["width", "height"]);
    return (_jsxs("svg", Object.assign({ width: width || "24", height: height || "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, props, { children: [_jsx("rect", { x: "0.5", y: "0.5", width: "23", height: "23", rx: "11.5", fill: "#F7FCFF" }), _jsx("rect", { x: "0.5", y: "0.5", width: "23", height: "23", rx: "11.5", stroke: "#E8ECEE" }), _jsx("mask", { id: "mask0_503_7072", maskUnits: "userSpaceOnUse", x: "0", y: "3", width: "24", height: "18", children: _jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M0 3V21H24V3H0Z", fill: "white" }) }), _jsx("g", { mask: "url(#mask0_503_7072)", children: _jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M12 17.625C15.1066 17.625 17.625 15.1066 17.625 12C17.625 8.89339 15.1066 6.375 12 6.375C8.89342 6.375 6.375 8.89339 6.375 12C6.375 15.1066 8.89342 17.625 12 17.625Z", fill: "#E31D1C" }) })] })));
};
export default Icon;
