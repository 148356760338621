import dayjs from "dayjs";
import { timeVestingInvalid } from "../constants";
export function truncateString(inputString, maxLength = 30) {
    if (inputString.length <= maxLength) {
        return inputString;
    }
    else {
        return inputString.slice(0, maxLength) + "...";
    }
}
export function convertWalletAddress(address, characters = 4) {
    if (!address)
        return "";
    const lastFourCharacters = address.slice(-characters);
    const result = `0x...${lastFourCharacters}`;
    return result;
}
export function shortenHex(hexString, keepStart = 6, keepEnd = 6) {
    if (hexString.startsWith("0x")) {
        hexString = hexString.slice(2); // Remove '0x' prefix if present
    }
    if (hexString.length <= keepStart + keepEnd) {
        return `0x${hexString}`;
    }
    const start = hexString.slice(0, keepStart);
    const end = hexString.slice(-keepEnd);
    return `0x${start}...${end}`;
}
/**
 * @description Convert timestamp to date
 * @param timestamp string
 * @param format default YYYY-MM-DD
 * @returns date
 */
export function timestampToDate(timestamp, format = "YYYY-MM-DD, HH:mm (UTC)") {
    if (timestamp === 0)
        return timeVestingInvalid;
    return dayjs.unix(timestamp).utc().format(format);
}
