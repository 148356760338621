var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { IconError, IconSuccess } from "src/assets";
import { ALERT_TYPE } from "src/constant";
import { AppModalConfirm } from "../AppModalConfirm";
export const ModalAlert = (_a) => {
    var { type, open, subContent, contentTitle, onCancel } = _a, props = __rest(_a, ["type", "open", "subContent", "contentTitle", "onCancel"]);
    const { t } = useTranslation();
    const renderIcon = () => {
        switch (type) {
            case ALERT_TYPE.ERROR:
                return _jsx(IconError, {});
            case ALERT_TYPE.SUCCESS:
                return _jsx(IconSuccess, {});
            default:
                return _jsx(IconError, {});
        }
    };
    return (_jsx(AppModalConfirm, Object.assign({ PaperProps: {
            style: {
                borderRadius: "20px",
                padding: "20px",
            },
        }, open: open, title: t("error"), isShowButtonConfirm: false, onCancel: onCancel, fullWidth: true, content: _jsxs(Box, { sx: {
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
            }, children: [renderIcon(), _jsxs(Box, { sx: { margin: "20px 0" }, children: [_jsx(Box, { sx: { fontWeight: 700 }, children: contentTitle }), _jsx(Box, { children: subContent })] })] }), cancelButtonProps: {
            variant: "contained",
            color: "primary",
            sx: {
                width: "100%"
            }
        } }, props)));
};
