import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ThemeProvider } from "@mui/material";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { AlertProvider, MenuProvider, MessageProvider, WagmiProvider, } from "src/contexts";
import { Routes } from "../routes";
import { AxiosManager } from "../services";
import { mainTheme } from "./constants";
import "./styles/index.scss";
dayjs.extend(utc);
const App = () => {
    return (_jsx(ThemeProvider, { theme: mainTheme, children: _jsx(WagmiProvider, { children: _jsx(MenuProvider, { children: _jsx(MessageProvider, { children: _jsxs(AlertProvider, { children: [_jsx(AxiosManager, {}), _jsx(Routes, {})] }) }) }) }) }));
};
export { App };
