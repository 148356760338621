import { createTheme, } from "@mui/material/styles";
const mainText = "#0F162E";
/**
 * @description Main Theme of the Application
 */
export const mainTheme = createTheme({
    components: {
        MuiDialogContent: {
            styleOverrides: {
                root: {
                    paddingTop: 0,
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    borderRadius: "36px",
                    textTransform: "unset",
                },
            },
        },
    },
    palette: {
        primary: {
            main: "#2E3549",
        },
        secondary: {
            main: "#0E132A",
        },
        success: {
            main: "#53AF97",
        },
        info: {
            main: "#1CBCE4",
            contrastText: "#FFFF",
        },
        warning: {
            main: "#F2B449",
        },
        error: {
            main: "#F25D49"
        },
        text: {
            primary: mainText,
        },
    },
});
