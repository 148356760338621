import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Drawer, List, ListItemButton, ListItemIcon, styled, } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import { HYPE_SEPOLIA_ADDRESS } from "src/config";
import { useMenuContext } from "src/contexts";
import { privateRoutes } from "src/routes";
import { IconLogo, IconWallet } from "src/assets/icons";
import { sideBarWidthClose, sideBarWidthOpen } from "src/ui/constants";
import { convertWalletAddress } from "src/ui/utils";
import styles from "./Sidebar.module.scss";
export const Sidebar = () => {
    const history = useHistory();
    const { pathname } = useLocation();
    const { t } = useTranslation();
    const { isOpenMenu, toggleMenu } = useMenuContext();
    const renderListItem = (node) => {
        const { icon: Icon, hidden, path } = node;
        const isActive = pathname === path;
        if (hidden) {
            return;
        }
        return (_jsx(Box, { sx: {
                display: "block",
                marginBottom: "5px",
            }, children: _jsxs(ListItemButton, { sx: {
                    minHeight: 48,
                    justifyContent: isOpenMenu ? "initial" : "center",
                    borderRadius: "30px",
                    background: isActive ? "#F4F3F3" : "transparent",
                    px: 2.5,
                }, onClick: () => history.push(node.path), children: [_jsx(ListItemIcon, { sx: {
                            minWidth: 0,
                            mr: isOpenMenu ? 3 : "auto",
                            justifyContent: "center",
                        }, children: Icon && _jsx(Icon, { isActive: isActive }) }), _jsx(Box, { sx: {
                            display: isOpenMenu ? "block" : "none",
                            color: (theme) => theme.palette.text.primary,
                            fontWeight: 700,
                        }, children: node.name })] }) }, node.path));
    };
    const openedMixin = () => ({
        width: sideBarWidthOpen,
        overflowX: "unset",
    });
    const closedMixin = (theme) => ({
        width: sideBarWidthClose,
        [theme.breakpoints.up("sm")]: {
            width: sideBarWidthClose,
        },
    });
    const DrawerBase = styled(Drawer, {
        shouldForwardProp: (prop) => prop !== "open",
    })(({ theme, open }) => (Object.assign(Object.assign(Object.assign({ width: sideBarWidthOpen, flexShrink: 0, whiteSpace: "nowrap", boxSizing: "border-box" }, (open && Object.assign(Object.assign({}, openedMixin()), { "& .MuiDrawer-paper": openedMixin() }))), (!open && Object.assign(Object.assign({}, closedMixin(theme)), { "& .MuiDrawer-paper": closedMixin(theme) }))), { "& .MuiDrawer-paper": {
            overflow: "unset",
            paddingTop: 30,
            width: open ? sideBarWidthOpen : sideBarWidthClose,
            [theme.breakpoints.up("sm")]: {
                width: open ? sideBarWidthOpen : sideBarWidthClose,
            },
        } })));
    return (_jsxs(DrawerBase, { variant: "permanent", open: isOpenMenu, className: styles.sidebarContainer, children: [_jsxs(Box, { sx: {
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    marginBottom: "60px",
                }, children: [_jsx(IconLogo, { width: 300 }), _jsxs(Box, { sx: { display: "flex", alignItems: "center", gap: 1 }, children: [_jsx(IconWallet, {}), _jsxs(Box, { sx: {
                                    fontSize: "12px",
                                    color: "#76768F",
                                }, children: ["HYPE ", t("tokenAddress"), ":"] })] }), _jsx(Box, { sx: {
                            display: "flex",
                            justifyContent: "center",
                            width: "80%",
                            overflow: "hidden",
                        }, children: convertWalletAddress(HYPE_SEPOLIA_ADDRESS, 15) })] }), _jsx("div", { className: styles.listContainer, children: _jsx(List, { sx: { paddingX: "20px" }, children: privateRoutes.map((route) => renderListItem(route)) }) })] }));
};
