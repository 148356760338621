var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
const Icon = (_a) => {
    var { width, height } = _a, props = __rest(_a, ["width", "height"]);
    return (_jsx("svg", Object.assign({ width: width || "24", height: height || "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, props, { children: _jsx("path", { d: "M18 9L12 14.6667L6 9", stroke: "#424B66", strokeWidth: "2", strokeLinecap: "round", strokeLinejoin: "round" }) })));
};
export default Icon;
