var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { TimelineConnector, TimelineContent, TimelineDot, TimelineItem, TimelineOppositeContent, TimelineSeparator, } from "@mui/lab";
import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { RowBetween } from "../common";
export const TimelineVestingItem = (_a) => {
    var { round, startTime, amount, percentage, currentRound = 0 } = _a, props = __rest(_a, ["round", "startTime", "amount", "percentage", "currentRound"]);
    const { t } = useTranslation();
    return (_jsxs(TimelineItem, Object.assign({}, props, { children: [_jsx(TimelineOppositeContent, { sx: { flex: "unset", width: "25%" }, children: _jsx(Box, { sx: {
                        fontWeight: 700,
                        color: currentRound + 1 === round ? "#1CBCE4" : "unset",
                    }, children: `${t("vestingRound")} ${round}` }) }), _jsxs(TimelineSeparator, { children: [_jsx(TimelineDot, { sx: {
                            background: "transparent",
                            width: "17px",
                            height: "17px",
                            border: (theme) => `2px solid ${theme.palette.info.main}`,
                        } }), _jsx(TimelineConnector, {})] }), _jsxs(TimelineContent, { children: [_jsxs(RowBetween, { children: [_jsx(Box, { children: t("startTime") }), _jsx(Box, { sx: { fontWeight: 500 }, children: startTime })] }), _jsxs(RowBetween, { children: [_jsx(Box, { children: t("amount") }), _jsxs(Box, { sx: { fontWeight: 500 }, children: [amount, " HYPE"] })] }), _jsxs(RowBetween, { children: [_jsx(Box, { children: t("percentage") }), _jsxs(Box, { sx: { fontWeight: 500 }, children: [percentage, "%"] })] })] })] })));
};
