import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box } from "@mui/material";
import { Icon404 } from "src/assets";
import { i18n } from "src/i18n";
export const Page404 = () => {
    return (_jsxs(Box, { sx: {
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
            width: "100%",
        }, children: [_jsx(Box, { sx: { fontWeight: 600, fontSize: "27px" }, children: i18n.t("pageNotFound") }), _jsx(Icon404, {})] }));
};
