import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import CssBaseline from "@mui/material/CssBaseline";
import ReactDOM from "react-dom/client";
import { I18nextProvider } from "react-i18next";
import { i18n } from "./i18n";
import reportWebVitals from "./reportWebVitals";
import { App } from "./ui/App";
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(_jsxs(_Fragment, { children: [_jsx(CssBaseline, {}), _jsx(I18nextProvider, { i18n: i18n, children: _jsx(App, {}) })] }));
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
