var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { AppModalConfirm } from "src/ui/components";
export const ModalConfirmClaim = (_a) => {
    var { open, isLoading = false, tokenAmount, onCancel, onConfirm } = _a, props = __rest(_a, ["open", "isLoading", "tokenAmount", "onCancel", "onConfirm"]);
    const { t } = useTranslation();
    return (_jsx(AppModalConfirm, Object.assign({ open: open, PaperProps: {
            sx: {
                borderRadius: "20px",
            },
        }, title: t("claimConfirmation"), onCancel: onCancel, onConfirm: onConfirm, isLoading: isLoading, content: _jsxs(Box, { sx: { padding: "20px 0 " }, children: [_jsxs(Box, { sx: {
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                    }, children: [t("confirmToClaim"), "\u00A0", _jsxs(Box, { sx: { fontWeight: 700 }, children: [tokenAmount, " HYPE"] })] }), t("toYourWallet")] }) }, props)));
};
