var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { TimelineOppositeContent } from "@mui/lab";
import Timeline from "@mui/lab/Timeline";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import { Box, Paper } from "@mui/material";
import { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { formatSINumber, timestampToDate } from "src/ui/utils";
import { formatUnits } from "viem";
import { RowBetween } from "../common";
import { TimelineVestingItem } from "./TimelineVestingItem";
export const TimelineVesting = (_a) => {
    var { startTime, time1stRound, amount, data, currentRound } = _a, props = __rest(_a, ["startTime", "time1stRound", "amount", "data", "currentRound"]);
    const { t } = useTranslation();
    const timelineContainerRef = useRef(null);
    useEffect(() => {
        if (!currentRound)
            return;
        const currentItemElement = document.getElementById(`${currentRound - 1}-round`);
        if (currentItemElement) {
            currentItemElement.scrollIntoView({
                behavior: "smooth",
                inline: "nearest",
            });
        }
    }, [currentRound]);
    return (_jsx(Paper, Object.assign({ ref: timelineContainerRef }, props, { sx: { borderRadius: "20px", height: "99%", padding: "10px" }, children: _jsx(Box, { sx: { height: "100%", overflow: "auto" }, children: _jsxs(Timeline, { children: [_jsxs(TimelineItem, { children: [_jsx(TimelineOppositeContent, { sx: { flex: "unset", width: "25%" }, color: "textSecondary", children: _jsx(Box, { sx: { fontWeight: 700 }, children: t("lockingPeriod") }) }), _jsxs(TimelineSeparator, { children: [_jsx(TimelineDot, { sx: {
                                            background: "transparent",
                                            width: "17px",
                                            height: "17px",
                                            border: (theme) => `2px solid ${theme.palette.info.main}`,
                                        } }), _jsx(TimelineConnector, {})] }), _jsxs(TimelineContent, { children: [_jsxs(RowBetween, { children: [_jsx(Box, { children: t("startTime") }), _jsx(Box, { sx: { fontWeight: 500 }, children: timestampToDate(startTime) })] }), _jsxs(RowBetween, { children: [_jsx(Box, { children: t("endTime") }), _jsx(Box, { sx: { fontWeight: 500 }, children: timestampToDate(time1stRound) })] }), _jsxs(RowBetween, { children: [_jsx(Box, { children: t("amount") }), _jsxs(Box, { sx: { fontWeight: 500 }, children: [amount === ""
                                                        ? "N/A"
                                                        : formatSINumber(formatUnits(BigInt(amount), 18)), " ", "HYPE"] })] })] })] }), data.map(({ amount, percentage, timestamp }, index) => (_jsx(TimelineVestingItem, { id: `${index}-round`, currentRound: currentRound, round: index + 1, amount: amount === ""
                            ? "N/A"
                            : formatSINumber(formatUnits(BigInt(amount), 18)), percentage: String(percentage), startTime: timestampToDate(timestamp) }, index)))] }) }) })));
};
