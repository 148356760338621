var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Chip } from "@mui/material";
import { disconnect } from "@wagmi/core";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { IconLogo } from "src/assets";
import { MESSAGE_TYPE, TOKEN_KEY } from "src/constant";
import { useAlertContext, useMessageContext } from "src/contexts";
import { ButtonConnectWallet, LanguageSelect } from "src/ui/components";
import { navbarHeight } from "src/ui/constants";
import { shortenHex } from "src/ui/utils";
import { useAccount } from "wagmi";
import styles from "./Navbar.module.scss";
export const Navbar = (_a) => {
    var props = __rest(_a, []);
    const { t } = useTranslation();
    const { showMessage } = useMessageContext();
    const { showSelectWallet, handleRefreshData, isInvestor, handleSetErrorInvestor, } = useAlertContext();
    const { address, isConnected } = useAccount();
    const handleDisconnect = () => __awaiter(void 0, void 0, void 0, function* () {
        try {
            const res = yield disconnect();
            localStorage.removeItem(TOKEN_KEY.TOKEN);
            showMessage(t("disconnectSuccessfully"), MESSAGE_TYPE.SUCCESS);
            handleRefreshData();
            return res;
        }
        catch (err) {
            showMessage(err.message, MESSAGE_TYPE.ERROR);
            return null;
        }
    });
    const handleCloseAlert = () => {
        handleSetErrorInvestor(false);
    };
    return (_jsxs("div", Object.assign({ className: clsx(styles.navbar), style: { height: navbarHeight } }, props, { children: [_jsx(IconLogo, {}), _jsxs(Box, { sx: { display: "flex", alignItems: "center" }, children: [isInvestor && (_jsx(Chip, { label: t("notVerifiedWallet"), color: "error", onDelete: handleCloseAlert, sx: { marginRight: "20px" }, style: { background: "#F25D491A" } })), _jsx(LanguageSelect, {}), _jsx(ButtonConnectWallet, { isConnected: isConnected, account: shortenHex(String(address)), onDisconnect: handleDisconnect, onConnectWallet: showSelectWallet })] })] })));
};
