var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { disconnect } from "@wagmi/core";
import axios from "axios";
import QueryString from "qs";
import { useTranslation } from "react-i18next";
import { NETWORK_CONFIG } from "../config";
import { MESSAGE_TYPE, TOKEN_KEY } from "../constant";
import { useAlertContext, useMessageContext } from "../contexts";
export function AxiosManager() {
    const { handleSetErrorInvestor, handleRefreshData } = useAlertContext();
    const { showMessage } = useMessageContext();
    const { t } = useTranslation();
    // Default
    axios.defaults.baseURL = NETWORK_CONFIG.API_BASE_URL;
    // axios.defaults.headers.common.domain = NETWORK_CONFIG.HOST;
    axios.defaults.paramsSerializer = {
        serialize: (params) => {
            return QueryString.stringify(params, {
                arrayFormat: "indices",
                allowDots: true,
            });
        },
    };
    axios.interceptors.request.use(function (config) {
        const token = localStorage.getItem(TOKEN_KEY.TOKEN);
        if (token) {
            config.headers["Authorization"] = `Bearer ${token}`;
        }
        return config;
    }, function (error) {
        // Do something with request error
        return Promise.reject(error);
    });
    axios.interceptors.response.use((response) => responseSuccessHandler(response), (error) => __awaiter(this, void 0, void 0, function* () {
        var _a;
        if (error.response.data.statusCode === 403) {
            yield disconnect();
            localStorage.removeItem(TOKEN_KEY.TOKEN);
            handleSetErrorInvestor(true);
            handleRefreshData();
            showMessage(t("notVerifiedWallet"), MESSAGE_TYPE.ERROR);
            return;
        }
        showMessage(((_a = error.response) === null || _a === void 0 ? void 0 : _a.data.message) || "", MESSAGE_TYPE.ERROR);
        return Promise.reject(error);
    }));
    return null;
}
const responseSuccessHandler = (response) => {
    return response;
};
