import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { footerHeight } from "../../constants";
export const Footer = (props) => {
    const { t } = useTranslation();
    return (_jsxs(Box, Object.assign({ sx: {
            display: "flex",
            justifyContent: "space-between",
            height: `${footerHeight}px`,
            padding: "0 50px",
            background: "white",
            alignItems: "center",
        } }, props, { children: [_jsx(Box, { children: "HYPETOON" }), _jsxs(Box, { children: ["\u00A9 2023 HYPETOON. ", t("allRightsReserved"), "."] })] })));
};
