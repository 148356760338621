var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { LoadingButton } from "@mui/lab";
import { Box, Dialog, DialogContent, DialogContentText, DialogTitle, IconButton, Stack, } from "@mui/material";
import { useTranslation } from "react-i18next";
import { IconClose, IconError } from "src/assets";
import { useNetwork } from "wagmi";
export const ModalAlertWrongNetwork = (_a) => {
    var { open, onSwitchNetwork, onDisconnect, onCancel, isDisableSwitch = false, isDisableDisconnect = false } = _a, props = __rest(_a, ["open", "onSwitchNetwork", "onDisconnect", "onCancel", "isDisableSwitch", "isDisableDisconnect"]);
    const { t } = useTranslation();
    const { chain } = useNetwork();
    return (_jsxs(Dialog, Object.assign({ open: open, PaperProps: {
            sx: {
                borderRadius: "20px",
            },
        } }, props, { children: [_jsx(DialogTitle, { sx: {
                    wordWrap: "break-word",
                    fontSize: "20px",
                    display: "flex",
                    justifyContent: "center",
                    fontWeight: 700,
                }, children: t("wrongNetwork") }), _jsx(IconButton, { "aria-label": "close", onClick: onCancel, disabled: isDisableSwitch, sx: {
                    position: "absolute",
                    right: 11,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }, children: _jsx(IconClose, {}) }), _jsxs(DialogContent, { children: [_jsx(DialogContentText, { sx: {
                            textAlign: "center",
                            color: "#030321",
                        }, children: _jsxs(Box, { sx: {
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                            }, children: [_jsx(IconError, {}), _jsxs(Box, { sx: { margin: "20px 0" }, children: [_jsx(Box, { sx: { fontWeight: 700 }, children: t("metamaskShouldOnETH") }), _jsx(Box, { children: t("currentWrongNetwork") })] })] }) }), _jsxs(Stack, { direction: "column", spacing: 2, sx: {
                            width: "100%",
                        }, justifyContent: "center", children: [_jsx(LoadingButton, { disabled: isDisableSwitch || !(chain === null || chain === void 0 ? void 0 : chain.unsupported), variant: "contained", color: "primary", sx: {
                                    padding: "10px 75px",
                                    width: "100%",
                                }, onClick: onSwitchNetwork, children: t("switchNetwork") }), _jsx(LoadingButton, { disabled: isDisableDisconnect, variant: "outlined", color: "primary", sx: {
                                    padding: "10px 75px",
                                    width: "100%",
                                }, onClick: onDisconnect, children: t("disconnectWallet") })] })] })] })));
};
