var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { LoadingButton } from "@mui/lab";
import { Dialog, DialogContent, DialogContentText, DialogTitle, IconButton, Stack, } from "@mui/material";
import { useTranslation } from "react-i18next";
import { IconClose } from "src/assets";
export const AppModalConfirm = (_a) => {
    var { open, title, isShowButtonCancel = true, isShowButtonConfirm = true, content, textCancel, textConfirm, onCancel, onConfirm, isLoading = false, cancelButtonProps } = _a, props = __rest(_a, ["open", "title", "isShowButtonCancel", "isShowButtonConfirm", "content", "textCancel", "textConfirm", "onCancel", "onConfirm", "isLoading", "cancelButtonProps"]);
    const { t } = useTranslation();
    return (_jsxs(Dialog, Object.assign({ open: open }, props, { children: [_jsx(DialogTitle, { sx: {
                    wordWrap: "break-word",
                    fontSize: "20px",
                }, children: title ? title : t("confirmation") }), _jsx(IconButton, { "aria-label": "close", onClick: onCancel, disabled: isLoading, sx: {
                    position: "absolute",
                    right: 11,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }, children: _jsx(IconClose, { color: isLoading ? "#cfcfcf" : "" }) }), _jsxs(DialogContent, { children: [_jsx(DialogContentText, { sx: {
                            textAlign: "center",
                            color: "#030321",
                        }, children: content }), _jsxs(Stack, { direction: "row", spacing: 7, sx: {
                            width: "100%",
                        }, justifyContent: "center", children: [isShowButtonCancel && (_jsx(LoadingButton, Object.assign({ disabled: isLoading, variant: "outlined", sx: {
                                    padding: "10px 75px",
                                    textTransform: "capitalize",
                                }, onClick: onCancel }, cancelButtonProps, { children: textCancel !== null && textCancel !== void 0 ? textCancel : t("close") }))), isShowButtonConfirm && (_jsx(LoadingButton, { disabled: isLoading, loading: isLoading, variant: "contained", color: "primary", sx: {
                                    padding: "10px 75px",
                                    textTransform: "capitalize",
                                }, onClick: onConfirm, children: textConfirm !== null && textConfirm !== void 0 ? textConfirm : t("confirm") }))] })] })] })));
};
