import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, MenuItem, Select, } from "@mui/material";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { IconFlagChina, IconFlagEng, IconFlagJapan, IconFlagKorea, } from "src/assets";
import { LANG } from "src/constant";
import { i18n } from "src/i18n";
export const LanguageSelect = (props) => {
    const { t } = useTranslation();
    const [lang, setLang] = useState(localStorage.getItem("language") || "en");
    const selectRef = useRef(null);
    const langOptions = [
        {
            flag: IconFlagEng,
            label: t("english"),
            value: LANG.ENGLISH,
            valueType: LANG.ENGLISH,
        },
        {
            flag: IconFlagKorea,
            label: t("korea"),
            value: LANG.KOREAN,
            valueType: LANG.KOREAN,
        },
        {
            flag: IconFlagJapan,
            label: t("japan"),
            value: LANG.JAPANESE,
            valueType: LANG.JAPANESE,
        },
        {
            flag: IconFlagChina,
            label: t("china"),
            value: LANG.CHINA,
            valueType: LANG.CHINA,
        },
    ];
    const handleChange = (event) => {
        i18n.changeLanguage(event.target.value);
        window.localStorage.setItem("language", event.target.value);
        setLang(event.target.value);
    };
    const renderValue = (value) => {
        switch (value) {
            case LANG.ENGLISH:
                return (_jsxs(Box, { sx: { display: "flex", alignItems: "center" }, children: [_jsx(IconFlagEng, { style: {
                                marginRight: "10px",
                                border: "1px solid #eeeeee",
                                borderRadius: "50%",
                            } }), _jsx(Box, { children: t("english") })] }));
            case LANG.JAPANESE:
                return (_jsxs(Box, { sx: { display: "flex", alignItems: "center" }, children: [_jsx(IconFlagJapan, { style: {
                                marginRight: "10px",
                                border: "1px solid #eeeeee",
                                borderRadius: "50%",
                            } }), _jsx(Box, { children: t("japan") })] }));
            case LANG.KOREAN:
                return (_jsxs(Box, { sx: { display: "flex", alignItems: "center" }, children: [_jsx(IconFlagKorea, { style: {
                                marginRight: "10px",
                                border: "1px solid #eeeeee",
                                borderRadius: "50%",
                            } }), _jsx(Box, { children: t("korea") })] }));
            case LANG.CHINA:
                return (_jsxs(Box, { sx: { display: "flex", alignItems: "center" }, children: [_jsx(IconFlagChina, { style: {
                                marginRight: "10px",
                                border: "1px solid #eeeeee",
                                borderRadius: "50%",
                            } }), _jsx(Box, { children: t("china") })] }));
        }
    };
    return (_jsx(Box, Object.assign({}, props, { children: _jsx(Select, { ref: selectRef, value: lang, onChange: handleChange, displayEmpty: true, renderValue: (value) => renderValue(value), sx: {
                height: "auto",
                ".MuiOutlinedInput-notchedOutline": { border: 0 },
                "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
                    border: 0,
                },
                "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    border: 0,
                },
            }, children: langOptions.map(({ value, label, flag: Flag }, index) => (_jsx(MenuItem, { value: value, sx: {
                    display: "flex",
                    justifyContent: "space-between",
                }, children: _jsxs(Box, { sx: { display: "flex", alignItems: "center" }, children: [_jsx(Flag, { style: { border: "1px solid #eeeeee", borderRadius: "50%" } }), _jsx(Box, { sx: { marginLeft: "15px" }, children: label })] }) }, index))) }) })));
};
