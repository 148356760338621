var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { readContracts, writeContract } from "@wagmi/core";
import BigNumber from "bignumber.js";
import dayjs from "dayjs";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { vestingAbi } from "src/abi/vestingAbi";
import { VESTING_CONTRACT_ADDRESS } from "src/config";
import { ALERT_TYPE, MESSAGE_TYPE, TOKEN_KEY } from "src/constant";
import { useAlertContext, useMessageContext } from "src/contexts";
import { InvestorService } from "src/services";
import { amountInvalid, timeInvalid } from "src/ui/constants";
import { formatSINumber, timestampToDate } from "src/ui/utils";
import { formatUnits } from "viem";
import { useAccount } from "wagmi";
export const useDashboard = () => {
    const { showMessage } = useMessageContext();
    const { showAlert, isRefreshData, handleSetErrorInvestor } = useAlertContext();
    const { t } = useTranslation();
    const { isConnected, status } = useAccount();
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState(null);
    const [scData, setScData] = useState();
    const getDashboard = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        if (isRefreshData || !isRefreshData) {
            setData(null);
            setScData(undefined);
            if (!localStorage.getItem(TOKEN_KEY.TOKEN) || status !== "connected")
                return;
            setIsLoading(true);
            try {
                const res = yield InvestorService.getDashboard();
                if (res.data.data) {
                    handleSetErrorInvestor(false);
                    setData(res.data.data);
                    const resScData = yield readContracts({
                        contracts: [
                            {
                                address: VESTING_CONTRACT_ADDRESS,
                                abi: vestingAbi,
                                functionName: "getClaimedAmountByUser",
                                args: [BigInt(res.data.data.id)],
                            },
                            {
                                address: VESTING_CONTRACT_ADDRESS,
                                abi: vestingAbi,
                                functionName: "getLastClaimByUser",
                                args: [BigInt(res.data.data.id)],
                            },
                        ],
                    });
                    if (resScData) {
                        setScData(resScData);
                        setIsLoading(false);
                    }
                }
            }
            catch (err) {
                setIsLoading(false);
                showMessage(err.response.data.meta.message, MESSAGE_TYPE.ERROR);
            }
        }
    }), [handleSetErrorInvestor, isRefreshData, showMessage, status]);
    useEffect(() => {
        getDashboard();
    }, [getDashboard]);
    const totalTokenAmount = useMemo(() => {
        if (!data)
            return "";
        return data.amount;
    }, [data]);
    const timeStartLock = useMemo(() => {
        if (!data)
            return 0;
        return data.start;
    }, [data]);
    // Current round, default start at 0
    const currentRound = useMemo(() => {
        var _a, _b;
        const now = dayjs().unix();
        if (!data)
            return -1;
        const allocations = JSON.parse(JSON.stringify(data.allocation));
        let roundNearestTimestamp = 0;
        for (let i = 0; i < allocations.length; i++) {
            if (((_a = allocations[i]) === null || _a === void 0 ? void 0 : _a.timestamp) > now) {
                roundNearestTimestamp = (_b = allocations[i]) === null || _b === void 0 ? void 0 : _b.timestamp;
                break;
            }
        }
        const round = allocations.findIndex((item) => item.timestamp === roundNearestTimestamp);
        if (round === -1) {
            return allocations.length - 1;
        }
        return round - 1;
    }, [data]);
    const unlockAmount = useMemo(() => {
        if (!data)
            return 0;
        const allocations = JSON.parse(JSON.stringify(data.allocation));
        const rounds = allocations.slice(0, currentRound + 1);
        return rounds.reduce((prev, current) => Number(prev) + Number(current.amount), 0);
    }, [currentRound, data]);
    const timeStart1stRound = useMemo(() => {
        var _a;
        if (!data)
            return 0;
        return ((_a = data.allocation[0]) === null || _a === void 0 ? void 0 : _a.timestamp) || 0;
    }, [data]);
    const timeNextRound = useMemo(() => {
        var _a;
        if (!data)
            return 0;
        if (currentRound > data.allocation.length - 1)
            return 0;
        return ((_a = data.allocation[currentRound + 1]) === null || _a === void 0 ? void 0 : _a.timestamp) || 0;
    }, [currentRound, data]);
    const timeCurrentRound = useMemo(() => {
        var _a;
        if (!data)
            return 0;
        if (currentRound > data.allocation.length - 1)
            return 0;
        return ((_a = data.allocation[currentRound]) === null || _a === void 0 ? void 0 : _a.timestamp) || 0;
    }, [currentRound, data]);
    const claimedAmount = useMemo(() => {
        if (!data)
            return 0;
        return Number(BigInt((scData === null || scData === void 0 ? void 0 : scData[0].result) || 0));
    }, [data, scData]);
    const timeLastUserClaimed = useMemo(() => Number(BigInt((scData === null || scData === void 0 ? void 0 : scData[1].result) || 0)), [scData]);
    const releasableTokenAmount = useMemo(() => {
        if (!data)
            return BigNumber(0);
        if (currentRound > data.allocation.length - 1)
            return BigNumber(0);
        const bUnlockAmount = new BigNumber(unlockAmount);
        const bClaimedAmount = BigNumber(claimedAmount);
        return bUnlockAmount.minus(bClaimedAmount);
    }, [claimedAmount, currentRound, data, unlockAmount]);
    const tokenAmountNextRound = useMemo(() => {
        var _a;
        if (!data)
            return "";
        if (currentRound > data.allocation.length - 1)
            return "";
        return ((_a = data.allocation[currentRound + 1]) === null || _a === void 0 ? void 0 : _a.amount) || "";
    }, [currentRound, data]);
    const listVestingRound = useMemo(() => {
        if (!data)
            return [];
        if (!data.allocation.length)
            return [];
        return data.allocation.map(({ timestamp, amount }) => {
            const percentage = new BigNumber(amount).dividedBy(new BigNumber(data.amount));
            return {
                timestamp,
                amount,
                percentage: percentage
                    .multipliedBy(100)
                    .decimalPlaces(3, BigNumber.ROUND_UP)
                    .toNumber(),
            };
        });
    }, [data]);
    const formatDate = "YYYY-MM-DD";
    // Display time
    const timeStart1stRoundDisplay = timeStart1stRound === 0
        ? timeInvalid
        : timestampToDate(timeStart1stRound, formatDate);
    const timeNextRoundDisplay = timeNextRound === 0
        ? timeInvalid
        : timestampToDate(timeNextRound, formatDate);
    const timeStartLockDisplay = useMemo(() => {
        if (!timeStartLock && isConnected)
            return "--";
        return timeStartLock === 0
            ? timeInvalid
            : timestampToDate(timeStartLock, formatDate);
    }, [isConnected, timeStartLock]);
    const timeCurrentRoundDisplay = timeCurrentRound === 0
        ? timeInvalid
        : timestampToDate(timeCurrentRound, formatDate);
    const timeLastUserClaimedDisplay = useMemo(() => {
        if (timeLastUserClaimed === 0 && isConnected)
            return "--";
        return timeLastUserClaimed === 0
            ? timeInvalid
            : timestampToDate(timeLastUserClaimed, formatDate);
    }, [isConnected, timeLastUserClaimed]);
    //----------------------------------------------------------------
    const convertAmountDisplay = (tokenAmount) => {
        return formatSINumber(formatUnits(BigInt(Number(tokenAmount)), 18));
    };
    // Amount display
    const unlockAmountDisplay = isConnected
        ? convertAmountDisplay(unlockAmount)
        : amountInvalid;
    const totalTokenAmountDisplay = isConnected
        ? convertAmountDisplay(totalTokenAmount)
        : amountInvalid;
    const tokenAmountNextRoundDisplay = isConnected
        ? convertAmountDisplay(tokenAmountNextRound)
        : amountInvalid;
    const releasableTokenAmountDisplay = isConnected
        ? convertAmountDisplay(releasableTokenAmount)
        : amountInvalid;
    const claimedAmountDisplay = isConnected
        ? convertAmountDisplay(claimedAmount)
        : amountInvalid;
    //----------------------------------------------------------------
    const handleClaimToken = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        if (!data)
            return null;
        try {
            const result = yield InvestorService.getDashboard();
            if (result.data.data) {
                const allocationParam = result.data.data.allocation.map(({ timestamp, amount }) => ({
                    timestamp: BigInt(timestamp),
                    amount: BigInt(amount),
                }));
                const { hash } = yield writeContract({
                    address: VESTING_CONTRACT_ADDRESS,
                    abi: vestingAbi,
                    functionName: "claimToken",
                    args: [BigInt(data.id), data.vault, allocationParam, data.proof],
                });
                if (hash) {
                    return hash;
                }
            }
        }
        catch (err) {
            console.error(err);
            showAlert({
                message: t("pleaseCheckTransaction"),
                title: t("claimFailedTitle"),
                titleAlert: t("claimFailed"),
                type: ALERT_TYPE.ERROR,
            });
            return null;
        }
    }), [data, showAlert, t]);
    return {
        data,
        totalTokenAmount,
        timeStartLock,
        currentRound,
        unlockAmount,
        timeStart1stRound,
        timeNextRound,
        claimedAmount,
        releasableTokenAmount,
        tokenAmountNextRound,
        listVestingRound,
        timeCurrentRound,
        timeLastUserClaimed,
        timeStart1stRoundDisplay,
        timeNextRoundDisplay,
        timeStartLockDisplay,
        timeCurrentRoundDisplay,
        timeLastUserClaimedDisplay,
        handleClaimToken,
        unlockAmountDisplay,
        totalTokenAmountDisplay,
        tokenAmountNextRoundDisplay,
        releasableTokenAmountDisplay,
        claimedAmountDisplay,
        isLoadingValue: isLoading,
    };
};
