import { jsx as _jsx } from "react/jsx-runtime";
import { CHAIN_ENV } from "src/config";
import { WagmiConfig, configureChains, createConfig } from "wagmi";
import { mainnet, sepolia } from "wagmi/chains";
import { MetaMaskConnector } from "wagmi/connectors/metaMask";
import { publicProvider } from "wagmi/providers/public";
const chainNetwork = CHAIN_ENV === "mainnet" ? mainnet : sepolia;
const { chains, publicClient } = configureChains([chainNetwork], [publicProvider()]);
const config = createConfig({
    autoConnect: true,
    publicClient,
    connectors: [new MetaMaskConnector({ chains })],
});
export function WagmiProvider({ children }) {
    return _jsx(WagmiConfig, { config: config, children: children });
}
