var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
const Icon = (_a) => {
    var { width, height } = _a, props = __rest(_a, ["width", "height"]);
    return (_jsxs("svg", Object.assign({ width: width || "68", height: height || "62", viewBox: "0 0 68 62", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, props, { children: [_jsx("path", { d: "M9.7422 61.0577C9.69887 61.0577 9.65887 61.0577 9.6122 61.0544C8.5622 61.0011 7.5322 60.7744 6.5522 60.3777C1.72887 58.4177 -0.597798 52.9077 1.35887 48.0877L25.7622 5.3344C26.6022 3.8144 27.8755 2.54107 29.4289 1.68107C33.9789 -0.838934 39.7322 0.817732 42.2489 5.3644L66.4922 47.7911C67.0322 49.0611 67.2622 50.0944 67.3189 51.1477C67.4489 53.6677 66.5889 56.0844 64.9022 57.9544C63.2155 59.8244 60.8989 60.9277 58.3822 61.0544L9.9822 61.0577H9.7422Z", fill: "url(#paint0_linear_166_497)" }), _jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M31.0815 23.9028C31.0815 22.2961 32.3915 20.9861 33.9982 20.9861C35.6049 20.9861 36.9149 22.2961 36.9149 23.9028V33.3294C36.9149 34.9394 35.6049 36.2461 33.9982 36.2461C32.3915 36.2461 31.0815 34.9394 31.0815 33.3294V23.9028ZM31.0815 44.7331C31.0815 43.1164 32.3915 41.7998 33.9982 41.7998C35.6049 41.7998 36.9149 43.0998 36.9149 44.6964C36.9149 46.3398 35.6049 47.6497 33.9982 47.6497C32.3915 47.6497 31.0815 46.3398 31.0815 44.7331Z", fill: "white" }), _jsx("defs", { children: _jsxs("linearGradient", { id: "paint0_linear_166_497", x1: "33.9986", y1: "0.502686", x2: "33.9986", y2: "61.0577", gradientUnits: "userSpaceOnUse", children: [_jsx("stop", { stopColor: "#F47766" }), _jsx("stop", { offset: "1", stopColor: "#E05543" })] }) })] })));
};
export default Icon;
