export var TOKEN_KEY;
(function (TOKEN_KEY) {
    TOKEN_KEY["TOKEN"] = "TOKEN";
    TOKEN_KEY["REFRESH_TOKEN"] = "REFRESH_TOKEN";
})(TOKEN_KEY || (TOKEN_KEY = {}));
export var ACCOUNT_ROLE;
(function (ACCOUNT_ROLE) {
    ACCOUNT_ROLE["EXAMINER"] = "EXAMINER";
    ACCOUNT_ROLE["PROVIDER"] = "PROVIDER";
    ACCOUNT_ROLE["ADMIN"] = "ADMIN";
})(ACCOUNT_ROLE || (ACCOUNT_ROLE = {}));
export var USER_KEY;
(function (USER_KEY) {
    USER_KEY["ROLE"] = "ROLE";
})(USER_KEY || (USER_KEY = {}));
