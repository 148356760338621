var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { connect, disconnect, signMessage, switchNetwork } from "@wagmi/core";
import { createContext, useCallback, useContext, useState, } from "react";
import { useTranslation } from "react-i18next";
import { CHAIN_SUPPORT_ID } from "src/config";
import { ALERT_TYPE, MESSAGE_TYPE, METAMASK_ERROR_CODE, TOKEN_KEY, } from "src/constant";
import { AuthService, InvestorService } from "src/services";
import { ModalAlert, ModalAlertWrongNetwork, ModalSelectWallet, } from "src/ui/components";
import { useAccount, useConnect } from "wagmi";
import { useMessageContext } from "../MessageProvider";
export const AlertContext = createContext({
    showAlert: () => {
        //
    },
    showSelectWallet: () => {
        //
    },
    handleLogin: () => {
        //
    },
    handleRefreshData: () => {
        //
    },
    handleSetErrorInvestor: () => {
        //
    },
    handleOpenSelectWallet: () => {
        //
    },
    handleOpenAlertWrongNetwork: () => {
        //
    },
    handleCloseAlertWrongNetwork: () => {
        //
    },
    isRefreshData: true,
    isInvestor: false,
});
// Create a component that will wrap your routes
export const AlertProvider = ({ children }) => {
    const { connectors } = useConnect();
    const { address } = useAccount();
    const { showMessage } = useMessageContext();
    const { t } = useTranslation();
    const [isOpen, setIsOpen] = useState(false);
    const [isOpenSelectWallet, setIsOpenSelectWallet] = useState(false);
    const [isOpenPopupWrongNetwork, setIsOpenPopupWrongNetwork] = useState(false);
    const [messageTitle, setMessageTitle] = useState("");
    const [message, setMessage] = useState("");
    const [type, setType] = useState(ALERT_TYPE.SUCCESS);
    const [titleAlert, setTitleAlert] = useState("");
    const [refreshData, setRefreshData] = useState(true);
    const [isInvestor, setIsInvestor] = useState(false);
    const [isDisableSwitch, setIsDisableSwitch] = useState(false);
    const handleShowPopup = useCallback((content) => {
        const { message, title, type, titleAlert } = content;
        setMessageTitle(title);
        setMessage(message);
        setType(type);
        setIsOpen(true);
        setTitleAlert(titleAlert);
    }, []);
    const handleClosePopup = useCallback(() => {
        setIsOpen(false);
    }, []);
    const handleOpenSelectWallet = useCallback(() => {
        setIsOpenSelectWallet(true);
    }, []);
    // check not install extension MetaMask
    const isMetaMask = () => {
        var _a;
        const windowObj = window;
        return !!(windowObj === null || windowObj === void 0 ? void 0 : windowObj.ethereum) || !!((_a = windowObj === null || windowObj === void 0 ? void 0 : windowObj.ethereum) === null || _a === void 0 ? void 0 : _a.isMetaMask);
    };
    const handleLogin = (wallet) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            const walletConnector = connectors.find((connector) => connector.id === wallet);
            if (!walletConnector)
                return;
            const { account, chain } = yield connect({ connector: walletConnector });
            if (chain === null || chain === void 0 ? void 0 : chain.unsupported)
                return;
            const nonceData = yield AuthService.getNonce({ address: account });
            const nonce = nonceData.data.data.nonce;
            if (nonce) {
                const signature = yield signMessage({
                    message: nonce,
                });
                if (signature) {
                    const res = yield AuthService.login({ address: account, signature });
                    if (res.data.data.token) {
                        localStorage.setItem(TOKEN_KEY.TOKEN, res.data.data.token);
                        const result = yield InvestorService.getDashboard();
                        if (result.data.data) {
                            showMessage(t("loginSuccess"));
                            handleRefreshData();
                        }
                    }
                }
            }
        }
        catch (err) {
            if (err.code === METAMASK_ERROR_CODE.REJECT) {
                yield disconnect();
                localStorage.removeItem(TOKEN_KEY.TOKEN);
                return;
            }
        }
    });
    const handleSelectWallet = (wallet) => __awaiter(void 0, void 0, void 0, function* () {
        if (!isMetaMask()) {
            //TODO: show popup error
            handleShowPopup({
                message: t("confirmInstallMetamask"),
                type: ALERT_TYPE.ERROR,
                title: t("cannotConnect"),
                titleAlert: t("error"),
            });
            return;
        }
        try {
            yield handleLogin(wallet);
        }
        catch (err) {
            if (err.code === METAMASK_ERROR_CODE.REJECT) {
                yield disconnect();
                localStorage.removeItem(TOKEN_KEY.TOKEN);
                return;
            }
            showMessage(err.message, MESSAGE_TYPE.ERROR);
        }
    });
    const handleRefreshData = () => {
        setRefreshData(!refreshData);
    };
    const handleSetErrorInvestor = (value) => {
        setIsInvestor(value);
    };
    const handleOpenAlertWrongNetwork = () => {
        setIsOpenPopupWrongNetwork(true);
    };
    const handleCloseAlertWrongNetwork = () => {
        setIsOpenPopupWrongNetwork(false);
    };
    const handleDisconnect = () => __awaiter(void 0, void 0, void 0, function* () {
        try {
            yield disconnect();
            localStorage.removeItem(TOKEN_KEY.TOKEN);
            handleRefreshData();
            setIsOpenPopupWrongNetwork(false);
        }
        catch (err) {
            showMessage(err.message, MESSAGE_TYPE.ERROR);
        }
    });
    const handleSwitchNetwork = () => __awaiter(void 0, void 0, void 0, function* () {
        try {
            setIsDisableSwitch(true);
            const { id } = yield switchNetwork({ chainId: CHAIN_SUPPORT_ID });
            if (id !== CHAIN_SUPPORT_ID)
                return;
            setIsOpenPopupWrongNetwork(false);
            localStorage.removeItem(TOKEN_KEY.TOKEN);
            const nonceData = yield AuthService.getNonce({
                address: address,
            });
            const nonce = nonceData.data.data.nonce;
            if (nonce) {
                const signature = yield signMessage({
                    message: nonce,
                });
                if (signature) {
                    const res = yield AuthService.login({
                        address: address,
                        signature,
                    });
                    if (res.data.data.token) {
                        localStorage.setItem(TOKEN_KEY.TOKEN, res.data.data.token);
                        const result = yield InvestorService.getDashboard();
                        if (result.data.data) {
                            showMessage(t("loginSuccess"));
                            handleRefreshData();
                            setIsOpenPopupWrongNetwork(false);
                        }
                    }
                }
            }
        }
        catch (err) {
            setIsOpenPopupWrongNetwork(false);
            if (err.code === METAMASK_ERROR_CODE.REJECT) {
                yield disconnect();
                localStorage.removeItem(TOKEN_KEY.TOKEN);
                return;
            }
        }
        finally {
            setIsDisableSwitch(false);
        }
    });
    return (_jsxs(AlertContext.Provider, { value: {
            showAlert: handleShowPopup,
            showSelectWallet: handleOpenSelectWallet,
            handleLogin,
            handleRefreshData,
            isRefreshData: refreshData,
            isInvestor,
            handleSetErrorInvestor,
            handleOpenSelectWallet,
            handleOpenAlertWrongNetwork,
            handleCloseAlertWrongNetwork,
        }, children: [children, isOpen && (_jsx(ModalAlert, { title: titleAlert, open: isOpen, isShowButtonConfirm: false, type: type, contentTitle: messageTitle, subContent: message, onCancel: handleClosePopup })), isOpenSelectWallet && (_jsx(ModalSelectWallet, { onClose: () => setIsOpenSelectWallet(false), open: isOpenSelectWallet, onSelectWallet: handleSelectWallet, onCancel: () => setIsOpenSelectWallet(false) })), isOpenPopupWrongNetwork && (_jsx(ModalAlertWrongNetwork, { onCancel: () => {
                    setIsOpenPopupWrongNetwork(false);
                    setIsDisableSwitch(false);
                }, isDisableSwitch: isDisableSwitch, open: isOpenPopupWrongNetwork, onSwitchNetwork: handleSwitchNetwork, onDisconnect: handleDisconnect }))] }));
};
export const useAlertContext = () => {
    return useContext(AlertContext);
};
