import axios from "axios";
const path = {
    nonce: "/auth/nonce",
    login: "/auth/login",
};
export const AuthService = {
    getNonce: (data) => {
        return axios.post(path.nonce, data);
    },
    login: (data) => {
        return axios.post(path.login, data);
    },
};
