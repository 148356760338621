import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import { PATHNAME } from "src/config";
import { DashboardLayout, LandingLayout, Page404 } from "src/ui/layout";
import { authRoutes, privateRoutes, publicRoutes } from "./RoutesList";
const isLogin = true;
const needRedirect = (pathname) => {
    if (isLogin) {
        return false;
    }
    return pathname !== PATHNAME.AUTH;
};
const PrivateRoute = ({ component: Component, path }) => {
    return (_jsx(Route, { path: path, render: (props) => !needRedirect(props.location.pathname) ? (_jsx(DashboardLayout, { children: _jsx(Component, Object.assign({}, props)) })) : (_jsx(Redirect, { to: { pathname: PATHNAME.AUTH } })) }));
};
const PublicRoute = ({ component: Component, path, exact }) => {
    return (_jsx(Route, { path: path, exact: exact, render: (props) => (_jsx(LandingLayout, { children: _jsx(Component, Object.assign({}, props)) })) }));
};
const AuthRoute = ({ component: Component, path }) => {
    return (_jsx(Route, { path: path, render: (props) => !isLogin ? (_jsx(LandingLayout, { children: _jsx(Component, Object.assign({}, props)) })) : (_jsx(Redirect, { from: "*", to: { pathname: PATHNAME.HOME } })) }));
};
export const Routes = () => {
    return (_jsx(BrowserRouter, { children: _jsxs(Switch, { children: [authRoutes.map(({ path, component }) => (_jsx(AuthRoute, { component: component, path: path }, path))), publicRoutes.map(({ path, component }) => (_jsx(PublicRoute, { component: component, path: path }, path))), privateRoutes.map(({ path, component, children }) => children ? (children.map((child) => (_jsx(PrivateRoute, { component: child.component, path: path + child.path, exact: path === PATHNAME.HOME }, path + child.path)))) : (_jsx(PrivateRoute, { component: component, path: path, exact: path === PATHNAME.HOME }, path))), _jsx(Route, { render: Page404 })] }) }));
};
