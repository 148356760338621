var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
const Icon = (_a) => {
    var { width, height } = _a, props = __rest(_a, ["width", "height"]);
    return (_jsx("svg", Object.assign({ width: width || "17", height: height || "16", viewBox: "0 0 17 16", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, props, { children: _jsxs("g", { id: "vuesax/linear/empty-wallet", children: [_jsxs("g", { id: "Group", children: [_jsx("path", { id: "Vector", d: "M12.5267 9.03341C12.2467 9.30674 12.0867 9.70008 12.1267 10.1201C12.1867 10.8401 12.8467 11.3667 13.5667 11.3667H14.8333V12.1601C14.8333 13.5401 13.7067 14.6667 12.3267 14.6667H4.67333C3.29333 14.6667 2.16667 13.5401 2.16667 12.1601V7.67342C2.16667 6.29342 3.29333 5.16675 4.67333 5.16675H12.3267C13.7067 5.16675 14.8333 6.29342 14.8333 7.67342V8.63342H13.4867C13.1133 8.63342 12.7733 8.78007 12.5267 9.03341Z", stroke: "#76768F", strokeLinecap: "round", strokeLinejoin: "round" }), _jsx("path", { id: "Vector_2", d: "M2.16667 8.27337V5.22674C2.16667 4.4334 2.65333 3.72671 3.39333 3.44671L8.68667 1.44671C9.51333 1.13337 10.4 1.74673 10.4 2.63339V5.16672", stroke: "#76768F", strokeLinecap: "round", strokeLinejoin: "round" })] }), _jsx("path", { id: "Vector_3", d: "M15.5392 9.3134V10.6868C15.5392 11.0534 15.2459 11.3534 14.8725 11.3667H13.5659C12.8459 11.3667 12.1859 10.8401 12.1259 10.1201C12.0859 9.70008 12.2459 9.30674 12.5259 9.03341C12.7725 8.78007 13.1125 8.63342 13.4859 8.63342H14.8725C15.2459 8.64676 15.5392 8.94673 15.5392 9.3134Z", stroke: "#76768F", strokeLinecap: "round", strokeLinejoin: "round" }), _jsx("path", { id: "Vector_4", d: "M5.16667 8H9.83333", stroke: "#76768F" })] }) })));
};
export default Icon;
