import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { DEFAULT_LANGUAGE } from "./config";
import cnTranslation from "./ui/locales/cn.json";
import enTranslation from "./ui/locales/en.json";
import jaTranslation from "./ui/locales/ja.json";
import krTranslation from "./ui/locales/kr.json";
const language = window.localStorage.getItem("language") || DEFAULT_LANGUAGE;
i18n.use(initReactI18next).init({
    resources: {
        en: { translation: enTranslation },
        ja: { translation: jaTranslation },
        kr: { translation: krTranslation },
        cn: { translation: cnTranslation },
    },
    lng: language,
    fallbackLng: language,
    interpolation: {
        escapeValue: false,
    },
});
export { i18n };
