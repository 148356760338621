var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Button, Dialog, DialogTitle, Divider, IconButton, styled, } from "@mui/material";
import { useTranslation } from "react-i18next";
import { IconClose, IconPool } from "src/assets";
import { timeInvalid } from "src/ui/constants";
const InfoRow = styled(Box)({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    margin: "15px 10px",
});
const TokenAmount = styled(Box)({
    fontWeight: 700,
});
const PoolInformation = ({ vestedTokenAmount, claimedToken, releasableTokenAmount, timeNextRound, tokenAmountNextRound, }) => {
    const { t } = useTranslation();
    return (_jsxs(Box, { sx: { width: "100%" }, children: [_jsxs(InfoRow, { children: [_jsx(Box, { children: t("vestedTokenAmount") }), _jsxs(TokenAmount, { children: [vestedTokenAmount, " HYPE"] })] }), _jsxs(InfoRow, { children: [_jsx(Box, { children: t("releasedTokenAmount") }), _jsxs(TokenAmount, { children: [claimedToken, " HYPE"] })] }), _jsxs(InfoRow, { children: [_jsx(Box, { children: t("releasableTokenAmount") }), _jsxs(TokenAmount, { children: [releasableTokenAmount, " HYPE"] })] }), _jsx(Divider, { sx: { marginBottom: "10px", border: "1px solid #E4E6E8" } }), _jsx(Box, { sx: { fontStyle: "italic", fontSize: "12px", marginBottom: "10px" }, children: !Number(releasableTokenAmount) && timeNextRound === timeInvalid ? (t("claimAllLockedToken")) : (_jsxs(Box, { children: [!Number(releasableTokenAmount) && (_jsx(Box, { sx: { display: "flex", alignItems: "center" }, children: t("waitNextVestingRound") })), _jsxs(Box, { sx: { display: "flex", alignItems: "center" }, children: [t("nextVestingRoundWillTakePlaceOn"), " \u00A0", _jsx(Box, { sx: { fontWeight: 700 }, children: timeNextRound })] }), _jsxs(Box, { sx: { display: "flex", alignItems: "center" }, children: [t("nextVestingRoundTokenAmount"), ": \u00A0", _jsxs(Box, { sx: { fontWeight: 700 }, children: [tokenAmountNextRound, " HYPE"] })] })] })) })] }));
};
export const ModalClaim = (_a) => {
    var { title, open, onCancel, vestedTokenAmount, claimedToken, releasableTokenAmount, timeNextRound, tokenAmountNextRound, onClickClaim, isAvailableClaim = true } = _a, props = __rest(_a, ["title", "open", "onCancel", "vestedTokenAmount", "claimedToken", "releasableTokenAmount", "timeNextRound", "tokenAmountNextRound", "onClickClaim", "isAvailableClaim"]);
    const { t } = useTranslation();
    return (_jsxs(Dialog, Object.assign({ open: open, PaperProps: {
            sx: {
                borderRadius: "16px",
                width: "464px",
            },
        } }, props, { children: [_jsxs(DialogTitle, { sx: {
                    wordWrap: "break-word",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                }, children: [t("myVestingPool"), _jsx(IconButton, { onClick: onCancel, children: _jsx(IconClose, {}) })] }), _jsxs(Box, { sx: {
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    padding: "30px",
                }, children: [_jsx(Box, { sx: { fontSize: "20px", fontWeight: 700 }, children: title }), _jsx(IconPool, { style: { margin: "20px 0" } }), _jsx(PoolInformation, { claimedToken: claimedToken, vestedTokenAmount: vestedTokenAmount, releasableTokenAmount: releasableTokenAmount, timeNextRound: timeNextRound, tokenAmountNextRound: tokenAmountNextRound }), _jsx(Button, { variant: "contained", sx: { width: "100%" }, onClick: isAvailableClaim
                            ? () => onClickClaim && onClickClaim()
                            : () => onCancel && onCancel(), children: isAvailableClaim ? t("next") : t("close") })] })] })));
};
