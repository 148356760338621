var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, CircularProgress } from "@mui/material";
export const AppLoading = (_a) => {
    var { content } = _a, props = __rest(_a, ["content"]);
    return (_jsxs(Box, Object.assign({}, props, { children: [_jsx(CircularProgress, {}), _jsx(Box, { sx: {
                    fontWeight: 500,
                    fontSize: "32px",
                    marginTop: "20px",
                }, children: content })] })));
};
