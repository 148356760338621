var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, CircularProgress, Paper } from "@mui/material";
import { useTranslation } from "react-i18next";
import { IconClaimedToken, IconLockToken, IconReleasableToken, IconUnlockToken, } from "src/assets";
import { INFO_TOKEN_TYPE } from "src/types";
import { RowBetween } from "../common";
export const InfoTokenBox = (_a) => {
    var { type, amount, date, isLoading = false } = _a, props = __rest(_a, ["type", "amount", "date", "isLoading"]);
    const { t } = useTranslation();
    const renderContent = () => {
        switch (type) {
            case INFO_TOKEN_TYPE.LOCKED:
                return {
                    headerColor: "#ED8C61",
                    icon: _jsx(IconLockToken, {}),
                    title1: t("totalLocked"),
                    title2: t("lockedOn"),
                };
            case INFO_TOKEN_TYPE.UNLOCKED:
                return {
                    headerColor: "#70D3B9",
                    icon: _jsx(IconUnlockToken, {}),
                    title1: t("nowVested"),
                    title2: t("vestOn"),
                };
            case INFO_TOKEN_TYPE.RELEASED:
                return {
                    headerColor: "#F9CC80",
                    icon: _jsx(IconClaimedToken, {}),
                    title1: t("released"),
                    title2: t("lastReleased"),
                };
            case INFO_TOKEN_TYPE.RELEASABLE:
                return {
                    headerColor: "#97A8E3",
                    icon: _jsx(IconReleasableToken, {}),
                    title1: t("releasable"),
                    title2: t("nextReleased"),
                };
        }
    };
    return (_jsxs(Paper, Object.assign({}, props, { sx: {
            borderRadius: "20px",
            overflow: "hidden",
            height: "98%",
            width: "98%",
        }, children: [_jsx(Box, { sx: {
                    background: renderContent().headerColor,
                    height: "30px",
                    width: "100%",
                } }), _jsxs(Box, { sx: {
                    padding: "20px",
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "space-between",
                }, children: [_jsx(Box, { sx: { marginBottom: "10px" }, children: renderContent().icon }), _jsxs(Box, { sx: { width: "100%" }, children: [_jsxs(RowBetween, { children: [_jsx(Box, { children: renderContent().title1 }), _jsxs(Box, { sx: { fontWeight: 700, display: "flex", alignItems: "center" }, children: [isLoading ? (_jsx(CircularProgress, { size: 15, sx: { marginRight: "10px" } })) : (amount), " ", "HYPE"] })] }), _jsxs(RowBetween, { children: [_jsx(Box, { children: renderContent().title2 }), _jsx(Box, { sx: { fontWeight: 700 }, children: date })] })] })] })] })));
};
