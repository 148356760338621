export var LANG;
(function (LANG) {
    LANG["ENGLISH"] = "en";
    LANG["KOREAN"] = "kr";
    LANG["JAPANESE"] = "ja";
    LANG["CHINA"] = "cn";
})(LANG || (LANG = {}));
export var MESSAGE_TYPE;
(function (MESSAGE_TYPE) {
    MESSAGE_TYPE["ERROR"] = "error";
    MESSAGE_TYPE["SUCCESS"] = "success";
    MESSAGE_TYPE["WARNING"] = "warning";
})(MESSAGE_TYPE || (MESSAGE_TYPE = {}));
export var ALERT_TYPE;
(function (ALERT_TYPE) {
    ALERT_TYPE["ERROR"] = "error";
    ALERT_TYPE["SUCCESS"] = "success";
    ALERT_TYPE["WARNING"] = "warning";
})(ALERT_TYPE || (ALERT_TYPE = {}));
export var METAMASK_ERROR_CODE;
(function (METAMASK_ERROR_CODE) {
    METAMASK_ERROR_CODE[METAMASK_ERROR_CODE["REJECT"] = 4001] = "REJECT";
})(METAMASK_ERROR_CODE || (METAMASK_ERROR_CODE = {}));
