import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { IconButton } from "@mui/material";
import { Box } from "@mui/system";
import { useState } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import { IconCopy, IconTick } from "src/assets";
export const CopyText = ({ text, valueCopy }) => {
    const [isCopied, setIsCopied] = useState(false);
    const handleCopy = () => {
        setIsCopied(true);
        setTimeout(() => setIsCopied(false), 5000);
    };
    return (_jsxs(Box, { sx: {
            display: "flex",
            alignItems: "center",
        }, children: [text, _jsx(CopyToClipboard, { text: valueCopy, onCopy: handleCopy, children: _jsx(IconButton, { children: isCopied ? _jsx(IconTick, {}) : _jsx(IconCopy, {}) }) })] }));
};
