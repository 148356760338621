var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Button, Menu, MenuItem } from "@mui/material";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { IconArrowDown, IconLogout, IconWallet } from "src/assets";
export const ButtonConnectWallet = (_a) => {
    var { account = "", isConnected = false, onDisconnect, onConnectWallet } = _a, props = __rest(_a, ["account", "isConnected", "onDisconnect", "onConnectWallet"]);
    const { t } = useTranslation();
    const buttonRef = useRef(null);
    const [isOpen, setIsOpen] = useState(false);
    const handleClickConnectWallet = () => {
        if (isConnected) {
            setIsOpen(!isOpen);
            return;
        }
        onConnectWallet && onConnectWallet();
    };
    const handleDisconnect = () => {
        setIsOpen(false);
        onDisconnect && onDisconnect();
    };
    const renderContentButton = () => {
        if (!isConnected)
            return t("connectWallet");
        return (_jsxs(Box, { sx: {
                display: "flex",
                alignItems: "center",
                gap: 1,
            }, children: [_jsx(IconWallet, {}), account, _jsx(IconArrowDown, {})] }));
    };
    return (_jsxs(Box, Object.assign({}, props, { children: [_jsx(Button, { ref: buttonRef, variant: isConnected ? "outlined" : "contained", onClick: handleClickConnectWallet, sx: { height: "40px", textTransform: "unset" }, children: renderContentButton() }), isOpen && isConnected && (_jsx(Menu, { onBlur: () => setIsOpen(false), open: isOpen, anchorEl: buttonRef.current, children: _jsxs(MenuItem, { sx: { width: "180px" }, onClick: handleDisconnect, children: [_jsx(IconLogout, {}), "\u00A0\u00A0", t("disconnect")] }) }))] })));
};
