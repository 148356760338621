import BigNumber from "bignumber.js";
const SI = [
    {
        value: 1,
        symbol: "",
    },
    {
        value: 1e6,
        symbol: "M",
    },
    {
        value: 1e9,
        symbol: "B",
    },
    {
        value: 1e12,
        symbol: "T",
    },
    // quadrillion
    {
        value: 1e15,
        symbol: "q",
    },
    // quintillion
    {
        value: 1e18,
        symbol: "Q",
    },
    {
        // sextillion
        value: 1e21,
        symbol: "s",
    },
    {
        // septillion
        value: 1e24,
        symbol: "S",
    },
    {
        value: 1e27,
        symbol: "o",
    },
    {
        value: 1e30,
        symbol: "n",
    },
    {
        value: 1e33,
        symbol: "d",
    },
    {
        value: 1e36,
        symbol: "U",
    },
    {
        value: 1e39,
        symbol: "D",
    },
    {
        value: 1e42,
        symbol: "T",
    },
    {
        value: 1e45,
        symbol: "Qt",
    },
    {
        value: 1e48,
        symbol: "Qd",
    },
    {
        value: 1e51,
        symbol: "Sd",
    },
    {
        value: 1e54,
        symbol: "St",
    },
    {
        value: 1e57,
        symbol: "O",
    },
    {
        value: 1e60,
        symbol: "N",
    },
    {
        value: 1e63,
        symbol: "v",
    },
    {
        value: 1e66,
        symbol: "c",
    },
];
export const formatSINumber = (value, decimalPlaces = 3) => {
    const bValue = new BigNumber(value);
    let i;
    for (i = SI.length - 1; i > 0; i--) {
        if (bValue.gte(SI[i].value)) {
            break;
        }
    }
    const formattedNumber = bValue
        .dividedBy(SI[i].value)
        .decimalPlaces(decimalPlaces)
        .toFormat();
    return `${formattedNumber}${SI[i].symbol}`;
};
export const formatNumber = (value, decimalPlaces = 3) => {
    const formattedNumber = new BigNumber(value)
        .decimalPlaces(decimalPlaces)
        .toFormat();
    return formattedNumber;
};
