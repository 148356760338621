import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { createContext, useCallback, useContext, useState, } from "react";
import { ToastContainer, toast } from "react-toastify";
import { IconErrorToast, IconSuccessToast } from "src/assets";
import { MESSAGE_TYPE } from "src/constant";
export const MessageContext = createContext({
    showMessage: () => {
        //
    },
});
// Create a provider that will wrap your routes
export const MessageProvider = ({ children }) => {
    const [duration, setDuration] = useState(3000);
    /**
     * @description Show alert
     * @param msg message to show
     * @param type type of alert must include MESSAGE_TYPE
     * @param duration time auto close (ms)
     * @returns void
     */
    const handleShowMessage = useCallback((msg, type = MESSAGE_TYPE.SUCCESS, duration = 3000) => {
        if (!msg)
            return;
        setDuration(duration);
        switch (type) {
            case MESSAGE_TYPE.SUCCESS:
                return toast.success(msg);
            case MESSAGE_TYPE.ERROR:
                return toast.error(msg);
            case MESSAGE_TYPE.WARNING:
                return toast.warning(msg);
            default:
                return toast.info(msg);
        }
    }, []);
    return (_jsxs(MessageContext.Provider, { value: {
            showMessage: handleShowMessage,
        }, children: [children, _jsx(ToastContainer, { position: "top-right", autoClose: duration, hideProgressBar: true, newestOnTop: false, closeOnClick: true, rtl: false, pauseOnFocusLoss: true, draggable: true, closeButton: false, icon: ({ type }) => {
                    if (type === "success")
                        return _jsx(IconSuccessToast, {});
                    if (type === "error")
                        return _jsx(IconErrorToast, {});
                    else
                        return "ℹ️";
                }, toastStyle: {
                    top: "50px",
                    borderRadius: "12px",
                } })] }));
};
export const useMessageContext = () => {
    return useContext(MessageContext);
};
