// NAME
export const STORE_NAME = "state";
//LANG
export const DEFAULT_LANGUAGE = "en";
// NETWORK
export const NETWORK_CONFIG = {
    // HOST: process.env.REACT_APP_URL,
    API_BASE_URL: process.env.REACT_APP_API_URL + "/api/v1/",
};
//TOKEN HYPE ADDRESS
export const HYPE_SEPOLIA_ADDRESS = process.env.TOKEN_HYPE_SEPOLIA_CONTRACT_ADDRESS || "";
//CONTRACT ADDRESS
export const VESTING_CONTRACT_ADDRESS = process.env
    .HYPE_VESTING_CONTRACT_ADDRESS;
// PATHNAME
export const PATHNAME = {
    HOME: "/",
    DASHBOARD: "/",
    AUTH: "/auth",
};
// CHAIN ENV
export const CHAIN_ENV = process.env.CHAIN_ENV || "testnet";
// CHAIN ID SUPPORT
export const CHAIN_SUPPORT_ID = CHAIN_ENV === "mainnet" ? Number(process.env.CHAIN_SUPPORT_ID || -1) : Number(process.env.CHAIN_SUPPORT_ID_TESTNET || -1);
// LAYOUT
export const LAYOUT_CONFIG = {
    isUseSidebar: false,
    isUseNavbar: true,
    isUseFooter: true,
};
